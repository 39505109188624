import React from "react";
import "./style.less";
import { Row, Col } from "antd";
import Link from "../Link";
import Img from "gatsby-image";

class Storytellers extends React.Component {
  render() {
    let imageCount = 0;

    return (
      <Row className="storytellers" type="flex">
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Row>
            {this.props.images &&
              this.props.images.map((image) => {
                imageCount++;
                return (
                  <Col xs={{ span: 12 }} key={"st-img-count-" + imageCount}>
                    <div className="image-wrap">
                      {image != null ? (
                        <Img fluid={image.localFile.childImageSharp.fluid} />
                      ) : null}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <h2>
            <span>We are filmmakers</span> Storytellers <br /> Directors <br />{" "}
            Producers
          </h2>
          <Link to="/work/" className="btn-primary">
            See our work
          </Link>
        </Col>
      </Row>
    );
  }
}

export default Storytellers;
