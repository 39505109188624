import React from "react";
import "./style.less";
import { Row, Col } from "antd";

class AboutUs extends React.Component {
  render() {
    let colCount = 0;

    return (
      <Row className={`about-us-title ${this.props.addClass}`}>
        <Col xs={{ span: 24 }}>
          <h2 className="huge">
            <span>{this.props.title}</span>
            {this.props.subTitle}
          </h2>
        </Col>
      </Row>
    );
  }
}

export default AboutUs;
