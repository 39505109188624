import React from 'react'
import './style.less';
import { Row, Col } from 'antd';

class Blurb extends React.Component {

    render(){

        return (
            <Row className="blurb">
                <Col xs={{span: 24}} md={{span: 14, offset: 2}} lg={{span: 10, offset: 2}}>
                    <p dangerouslySetInnerHTML={{__html: this.props.content }} />
                </Col>
            </Row>
        );
    }
}

export default Blurb