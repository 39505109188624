import React from "react";
import "./style.less";
import { Row, Col } from "antd";
import ReactHtmlParser from "react-html-parser";

class AboutUs extends React.Component {
  render() {
    let colCount = 0;

    return (
      <Row className="about-us">
        <Col xs={{ span: 24 }}>
          <p className="lead">{ReactHtmlParser(this.props.header)}</p>
        </Col>
        {this.props.columns &&
          this.props.columns.map((column) => {
            colCount++;
            return (
              <Col
                xs={{ span: 24 }}
                md={{ span: 8 }}
                key={"about-col-count-" + colCount}
              >
                <p>{ReactHtmlParser(column.column_content)}</p>
              </Col>
            );
          })}
      </Row>
    );
  }
}

export default AboutUs;
