import React, { Component } from "react";
import Wrapper from "../components/Wrapper";
import PageTitle from "../components/PageTitle/index";
import Blurb from "../components/Blurb/index";
import Letterbox from "../components/Letterbox/index";
import AboutUs from "../modules/AboutUs/index";
import Logos from "../components/Logos/index";
import AboutUsTitle from "../modules/AboutUsTitle/index";
import Storytellers from "../components/Storytellers/index";
import StorytellersLarge from "../components/StoyrtellersLarge/index";
import ReactHtmlParser from "react-html-parser";
import { graphql } from "gatsby";
import { Col, Row } from "antd";

class About extends Component {
  render() {
    const page = this.props.data.wordpressPage;
    const acfFields = this.props.data.wordpressPage.acf;

    let seoTitle = acfFields.meta_title;
    let seoDescription = acfFields.meta_description;
    if (seoTitle === "") {
      seoTitle = page.title;
    }

    let path = this.props.data.wordpressPage.slug;
    if (path === "home") path = "";
    // add a trailing slash
    if (path.substr(-1) !== "/" && path !== "") path += "/";

    return (
      <Wrapper
        title={seoTitle}
        description={seoDescription}
        og_title={acfFields.og_title}
        og_description={acfFields.og_description}
        og_image={
          acfFields.og_image
            ? acfFields.og_image.localFile.childImageSharp.original.src
            : null
        }
        twitter_card_type={acfFields.twitter_card_type}
        twitter_title={acfFields.twitter_title}
        twitter_description={acfFields.twitter_description}
        twitter_image={
          acfFields.twitter_image
            ? acfFields.twitter_image.localFile.childImageSharp.original.src
            : null
        }
        path={`/${path}`}
      >
        <PageTitle
          title={page.title}
          image={
            page.acf.page_title_banner.localFile.childImageSharp.original.src
          }
        />
        <Blurb content={page.acf.page_blub} />
        <AboutUsTitle
          title={"Team"}
          subTitle={
            <i>
              Our strength <br />
              is our staff.
            </i>
          }
          addClass={`pb-0`}
        />
        <Logos logos={page.acf.client_logos} />
        <AboutUs
          header={page.acf.story_heading}
          columns={page.acf.story_columns}
        />
        <div style={{ display: "block", height: 250 }} />
        <StorytellersLarge
          title={
            <h2>
              <span>What we do </span>If you need
              <br /> it, we can
              <br /> write it
            </h2>
          }
          content={page.acf.storytellers_section}
        />
        <AboutUsTitle title={"Our Goal"} subTitle={null} />
        <Row>
          <Col xs={{ span: 20, ofset: 2 }} md={{ span: 16, offset: 4 }}>
            <p>{ReactHtmlParser(page.acf.goal_blurb)}</p>
          </Col>
        </Row>
        <Letterbox
          header={page.acf.letterbox_heading}
          ctaText={page.acf.letterbox_cta_text}
          ctaLink={page.acf.letterbox_cta_link}
        />
      </Wrapper>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      acf {
        page_title_banner {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
        page_blub
        client_logos {
          id
          localFile {
            childImageSharp {
              fluid(
                maxWidth: 175
                maxHeight: 90
                quality: 90
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        story_heading
        story_columns {
          column_content
        }
        storytellers_section {
          background_image {
            localFile {
              childImageSharp {
                original {
                  width
                  height
                  src
                }
              }
            }
          }
          heading
          blurb
          cta_text
          cta_link
        }
        goal_blurb
        letterbox_heading
        letterbox_cta_text
        letterbox_cta_link
        meta_title
        meta_description
        og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default About;
