import React from "react";
import "./style.less";
import { Row, Col } from "antd";
import { Link } from "@reach/router";
import ReactHtmlParser from "react-html-parser";

class Letterbox extends React.Component {
  render() {
    return (
      <Row className="letterbox">
        <Col xs={{ span: 24 }}>
          <h2 className="text-center">{ReactHtmlParser(this.props.header)}</h2>
          <p className="text-center">
            <Link to={this.props.ctaLink} className="btn btn-primary">
              <span>{ReactHtmlParser(this.props.ctaText)}</span>
            </Link>
          </p>
        </Col>
      </Row>
    );
  }
}

export default Letterbox;
